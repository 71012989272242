var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[(_vm.hasLoaded)?_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-row',{staticClass:"mb-6",attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-layout',{attrs:{"align-center":""}},[_c('h1',{staticClass:"text-h4"},[_vm._v("Invoice #"+_vm._s(_vm.invoice.invoiceNumber))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"px-0 ml-5",attrs:{"min-width":"40px","to":{ name: 'print-invoice', params: { invoiceId: _vm.invoice.id }},"target":"_blank","color":"primary"}},on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}],null,false,3203907825)},[_c('span',[_vm._v("Print Invoice")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"px-0 mr-2",attrs:{"min-width":"40px","color":"error"},on:{"click":_vm.deleteInvoice}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,1115028671)},[_c('span',[_vm._v("Delete Invoice")])]),_c('v-btn',{attrs:{"disabled":_vm.invoice.ccc1Exported,"to":{ name: 'edit-invoice', params: { invoiceId: _vm.invoice.id }},"color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v("Edit Invoice")],1)],1)],1),(_vm.hasLoaded)?_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"label":"Date","filled":"","value":_vm.invoice.formattedDate,"readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"label":"RO","filled":"","value":_vm.invoice.roNumber || 'None',"readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"label":"Tech","filled":"","value":_vm.invoice.tech ? _vm.invoice.tech.name : 'None',"readonly":""}})],1)],1)],1),_c('v-data-table',{staticClass:"clickable",attrs:{"headers":_vm.headers,"no-data-text":"No Products Found","disable-pagination":"","items":_vm.rows,"hide-default-footer":"","items-per-page":-1},on:{"click:row":function($event){}},scopedSlots:_vm._u([{key:"item.listPrice",fn:function(ref){
var product = ref.item;
return [_c('span',[_vm._v("$"+_vm._s(_vm._f("round")(Number(product.listPrice).toFixed(2),2)))])]}},{key:"item.costPrice",fn:function(ref){
var product = ref.item;
return [_c('span',[_vm._v("$"+_vm._s(_vm._f("round")(Number(product.costPrice).toFixed(2),2)))])]}},{key:"item.listSubtotal",fn:function(ref){
var product = ref.item;
return [_c('span',[_vm._v("$"+_vm._s(_vm._f("round")(Number(product.listSubtotal).toFixed(2),2)))])]}},{key:"item.costSubtotal",fn:function(ref){
var product = ref.item;
return [_c('span',[_vm._v("$"+_vm._s(_vm._f("round")(Number(product.costSubtotal).toFixed(2),2)))])]}}],null,false,1439190441)})],1):_vm._e(),_c('v-layout',{staticClass:"mt-5",attrs:{"justify-center":""}},[(_vm.invoice.shop.exportCCC1 && _vm.invoice.shop.ccc1LicenseNo && _vm.invoice.roNumber)?_c('v-btn',{attrs:{"color":"primary","disabled":_vm.invoice.ccc1Exported},on:{"click":_vm.exportToCCC1}},[_vm._v(_vm._s(_vm.invoice.ccc1Exported ? "Exported to CCC1" : "Export to CCC1")),_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(_vm.invoice.ccc1Exported ? "mdi-lock" : "mdi-export"))])],1):_vm._e()],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }